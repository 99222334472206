#app-nav {
    position: relative;
    padding: 5px 10px;
    width: 100%;
    z-index: 1;
    background: url("../assets/nav_bkg.jpg") repeat left top;

    @media(max-width: $laptop) {
        padding: 5px 0;
    }

    .icon-bar {
        position: absolute;
        top: 0.5rem;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        max-width: 300px;
        width: 16vw;

        @media(max-width: $tablet) {
            display: none;
        }
    }

    #desktop-nav {

        .navbar-toggler {
            border: 0 !important;
            position: relative;
            transition: all 0.3s ease;
            height: 46px;
            width: 46px;
            top: 0;
            right: -12px;

            &:hover {
                background-color: rgba(255, 255, 255, 0.4);
                border: 0;
                border-radius: 50%;
            }

            .navbar-toggler-icon {
                position: absolute;
                left: 7px;
                top: 7px;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(63, 117, 18)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
            }
        }

        .navbar-brand {
            width: 62px;
            padding: 3px 0 0 0;
            margin-right: 1.4rem;

            @media(max-width: $laptop) {
                width: 52px;
                margin-right: 1rem;
            }

            @media(max-width: $mobile) {
                width: 42px;
                margin-right: 0.5rem;
            }
        }

        .navbar-nav {
            a {
                position: relative;
                color: $green;
                padding: 0 0.6rem;
                text-decoration: none;
                font-size: 1rem;
                text-align: center;

                @media(max-width: $laptop) {
                    font-size: 0.9rem;
                }

                @media(max-width: 1799px) {
                    padding: 0 0.3rem;
                }

                @media(max-width: $mobile) {
                    padding: 0 0.3rem;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: -15px;
                    right: 0;
                    left: 0;
                    height: 14px;
                    text-align: center;
                    margin: auto;
                    opacity: 0;
                    transition: opacity 0.5s ease;
                    background: url("../assets/tots_dots_green.png") no-repeat center top;
                    background-size: contain;

                    @media(max-width: $tablet) {
                        height: 11px;
                        top: -10px;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -15px;
                    right: 0;
                    left: 0;
                    height: 15px;
                    width: 3.4vw;
                    max-width: 68px;
                    margin: auto;
                    text-align: center;
                    opacity: 0;
                    transition: opacity 0.5s ease;
                    background: url("../assets/tots_linebar_white.png") no-repeat center bottom;
                    background-size: 100%;

                    @media(max-width: 1799px) {
                        bottom: -15px;
                    }

                    @media(max-width: $tablet) {
                        bottom: -10px;
                    }
                }

                &.active:after,
                &:hover:after,
                &.active:before,
                &:hover:before {
                    opacity: 1;
                }
            }
        }

        .social-block {
            @media(max-width: $mobile) {
                margin-top: -8px;
            }

            a {
                background-color: $white;
                margin-left: 10px;
                padding: 3px 4px;
                border-radius: 50%;
                text-align: center;

                @media(max-width: $mobile) {
                    font-size: 0.8rem;
                }

                i {
                    color: $green;
                    transition: color 0.3s ease-in;
                }

                &:hover {

                    i {
                        color: $black;
                    }
                }
            }
        }
    }
}

#mobile-nav {
    width: 80%;
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    height: 100vh;
    border-left: 4px solid $green;
    background: url("../assets/nav_bkg.jpg") repeat left top;
    transition: transform 0.4s ease;
    @include transform(translateX(100%));

    &.active {
        @include transform(translateX(0));
    }

    .icon-bar {
        max-width: 160px;
        text-align: center;
        margin: auto;
    }

    .navbar-nav {
        .link {
            position: relative;
            color: $green;
            margin: 1.2rem auto;
            text-decoration: none;
            font-size: 1.2rem;
            width: 240px;

            &:before {
                content: '';
                position: absolute;
                top: -15px;
                right: 0;
                left: 0;
                height: 14px;
                text-align: center;
                margin: auto;
                opacity: 0;
                transition: opacity 0.5s ease;
                background: url("../assets/tots_dots_green.png") no-repeat center top;
                background-size: contain;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: -20px;
                right: 0;
                left: 0;
                height: 13px;
                max-width: 81px;
                width: 240px;
                margin: auto;
                text-align: center;
                opacity: 0;
                transition: opacity 0.5s ease;
                background: url("../assets/tots_linebar_white.png") no-repeat center bottom;
                background-size: 100%;

                @media(max-width: 1799px) {
                    bottom: -15px;
                }
            }

            &.active:after,
            &:hover:after,
            &.active:before,
            &:hover:before {
                opacity: 1;
            }
        }
    }

    .social-block {
        margin-top: 2rem;

        a {
            background-color: $white;
            margin: 0 10px;
            padding: 5px 5px 3px 5px;
            border-radius: 50%;
            font-size: 1.4rem;

            i {
                color: $green;
                transition: color 0.3s ease-in;
            }

            &:hover {

                i {
                    color: $black;
                }
            }
        }
    }
}

.hamburger-icon {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.4);
    transform-origin: center;

    &:hover {
        background: rgba(255, 255, 255, 0.7);
        transform: rotate(180deg);
    }

    &.active {
        .icon-1 {
            transform: rotate(45deg);
        }

        .icon-2 {
            opacity: 0;
        }

        .icon-3 {
            transform: rotate(-45deg);
        }
    }
}

.icon-1,
.icon-2,
.icon-3 {
    position: absolute;
    top: 19.25px;
    left: 0;
    right: 0;
    margin: auto;
    width: 20px;
    height: 3px;
    background-color: black;
    transition: all 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
}
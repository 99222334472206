@import "mixins";
@import "vars";

#home {
    position: relative;
    overflow: hidden;

    #hero {
        position: relative;

        .hero-block {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;

            .brand-logo {
                margin: auto;
                text-align: center;
                width: 31vw;
                max-width: 615px;
                position: relative;
                top: -40px;
                z-index: 1;

                @media(max-width: $fullsize) {
                    top: -30px;
                }

                @media(max-width: 1699px) {
                    top: -30px;
                    width: 50%;
                }

                @media(max-width: $laptop) {
                    width: 70%;
                }

                @media(max-width: 1199px) {
                    width: 42%;
                }

                @media(max-width: $tablet) {
                    top: -22px;
                    width: 52%;
                }

                @media(max-width: $mobile) {
                    width: 340px;
                    top: -30px;
                }

                @media(max-width: $phone) {
                    width: 300px;
                }
            }

            .wishlist-block {
                position: relative;
                z-index: 1;
                margin: 1% auto;
                height: auto;
                text-align: center;
                color: #3b4e32;

                @media(max-width: $mobile) {
                    margin: 2% auto 0;
                }

                h2 {
                    font-size: calc(1rem + 1vw);
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: -32%;
                    right: 0;
                    left: 0;
                    height: 23px;
                    text-align: center;
                    margin: auto;
                    background: url("../assets/tots_dots_green.png") no-repeat center top;
                    background-size: contain;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -20%;
                    right: 0;
                    left: 0;
                    height: 32px;
                    max-width: 220px;
                    margin: auto;
                    text-align: center;
                    background: url("../assets/tots_line_green.png") no-repeat center bottom;
                    background-size: 100%;

                    @media(max-width: $fullsize) {
                        bottom: -30%;
                    }

                    @media(max-width: $mobile) {
                        max-width: 180px;
                    }
                }

                .icon {
                    width: 2.2vw;
                    margin: 0 0.6rem;

                    @media(max-width: $fullsize) {
                        width: calc(1rem + 2vw);
                    }

                    @media(max-width: $laptop) {
                        width: 3.5vw;
                        margin: 0 0.4rem 0.6rem;
                    }

                    @media(max-width: $mobile) {
                        width: 8vw;
                        margin: 0 0.4rem 0.6rem;
                    }

                    &.playstation-5 {
                        width: 4vw;

                        @media(max-width: $fullsize) {
                            width: calc(1rem + 3vw);
                        }

                        @media(max-width: $mobile) {
                            width: 9.2vw;
                        }
                    }
                }
            }
        }

        .hero-bkg {
            background-color: #fefefe;
            background-image: url('../assets/TOTS_LandingPage_bleed_fallback.jpg');
            background-size: cover;
            background-position: center;
            height: 100vh;
            min-height: 1070px;

            @media(max-width: $laptop) {
                min-height: inherit;
            }

            @media(min-width: 2499px) {
                min-height: 1370px;
            }

            @media(min-width: 2699px) {
                min-height: 1670px;
            }

            @media(max-width: 1180px) {
                min-height: 700px;
                height: 80vh;
            }

            @media(max-width: $mobile) {
                max-height: 700px;
            }
        }

        #hero-video {
            position: relative;
            z-index: 0;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        #hero-floating-left-leaves {
            position: absolute;
            z-index: 3;
            left: 0;
            bottom: -18vw;
            width: 21vw;
        }

        #hero-floating-right-leaves {
            position: absolute;
            z-index: 3;
            right: 0;
            bottom: 7vw;
            width: 29vw;
        }

        #hero-floating-papers {
            position: absolute;
            z-index: 2;
            right: -5vw;
            margin: auto;
            bottom: 0;
            width: 25vw;
            @include transform(rotate(-20deg));
        }

        #hero-floating-papers2 {
            position: absolute;
            z-index: 2;
            left: -5vw;
            margin: auto;
            bottom: 0;
            width: 25vw;
            @include transform(rotate(60deg));
        }
    }

    .frame-section {
        position: relative;
        text-align: center;
        padding: 4vw 30px 9vw;
        z-index: 1;

        @media(max-width: $tablet) {
            padding: 4vw 0 2vw;
        }

        .frame-border {
            position: relative;
            margin: auto;
            width: 71vw;

            @media(max-width: $tablet) {
                width: 88vw;
            }

            @media(max-width: $mobile) {
                width: 95vw;
            }

            .frame-title {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                height: 13%;

                h3 {
                    color: #6f531b;
                    font-size: calc(1.4rem + 1vw);
                    margin-bottom: 0;

                    @media(max-width: 1920px) {
                        font-size: calc(1.2rem + 1vw);
                    }

                    @media(max-width: $mobile) {
                        font-size: calc(0.9rem + 1vw);
                    }
                }
            }
        }
    }

    #main-section {
        position: relative;

        @media(max-width: $tablet) {
            margin-bottom: 2rem;
        }

        #scene {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            @media(max-width: $tablet) {
                margin-top: 6vw;
            }

            @media(max-width: $mobile) {
                margin-top: 15vw;
            }

            #scene-bkg {
                left: -1vw !important;
                top: 0px;
                width: 101vw;
                max-width: 2583px;
            }

            #scene-bkg-mobile {
                left: -1vw !important;
                top: 0px;
                width: 101vw;
                max-width: 2583px;
            }

            #scene-sky {
                left: -1vw !important;
                width: 101vw;
                max-width: 2583px;
            }

            #scene-floating {
                top: -10vw !important;
                left: -1vw !important;
                width: 101vw;
                max-width: 2583px;
            }

            #scene-highlight {
                left: -3.5% !important;
            }

            #scene-particles {
                left: -10% !important;
                top: inherit !important;
                bottom: -4%;
            }
        }


        #watch-trailer {
            position: relative;

            @media(min-width: 2040px) {
                height: 62vw;
            }

            @media(min-width: $widescreen) {
                height: 64vw;
            }

            @media(min-width: 2561px) {
                height: 54vw;
            }

            .frame-section {
                padding-top: 0;

                .frame-border {
                    @media(min-width: $widescreen) {
                        top: 10vw;
                    }
                }

                .frame-title {
                    height: 12%;
                }
            }

            #trailer-char {
                position: absolute;
                z-index: 1;
                bottom: -6vw;
                left: 2vw;
                width: 24vw;

                @media(min-width: $fullsize) {
                    bottom: -9%;
                }

                @media(min-width: $widescreen) {
                    bottom: 0;

                    img {
                        width: 100%;
                    }
                }

                @media(max-width: $mobile) {
                    bottom: -11vw;
                }
            }

            #video-player {
                position: absolute;
                top: 18%;
                left: 0;
                right: 0;
                margin: auto;
                width: 82%;
                max-width: 1110px;
                border-radius: $border-radius;
                background-color: $black;

                img {
                    border-radius: $border-radius;
                }

                iframe {
                    border-radius: $border-radius;
                }

                .play-btn-wrapper {
                    position: absolute;
                    top: 50%;
                    opacity: 0.75;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    cursor: pointer;
                    transform: translateY(-50%);
                    transition: opacity 1s ease;

                    .play-btn {
                        position: relative;
                        width: 100%;
                        cursor: pointer;
                        transition: transform 0.8s ease;
                        color: $white;
                        font-size: 7rem;
                        @include transform(scale(0.96));

                        @media(max-width: $laptop) {
                            font-size: 5rem;
                        }
                    }
                }

                &:hover {
                    .play-btn-wrapper {
                        opacity: 0.85;

                        .play-btn {
                            @include transform(scale(1.08));
                        }
                    }
                }
            }
        }

        #about {
            position: relative;

            @media(max-width: $tablet) {
                margin-top: 8vw;
            }

            #about-sm-paperfly {
                position: absolute;
                z-index: 2;
                left: 0;
                bottom: 30vw;
                width: 8vw;
                @include transform(rotate(20deg));
            }

            #about-md-paperfly {
                position: absolute;
                z-index: 2;
                left: 4vw;
                bottom: 5vw;
                width: 18vw;
                @include transform(rotate(15deg));
            }

            #about-flowers {
                position: absolute;
                z-index: 2;
                right: 1vw;
                bottom: 4vw;
                width: 36vw;
            }

            #about-char-2 {
                position: absolute;
                right: -1vw;
                bottom: 2vw;
                width: 27vw;
            }

            #about-image {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 6.2vw;
                width: 100%;
                margin: auto;

                @media(min-width: $widescreen) {
                    bottom: 8rem;
                }

                @media(max-width: $fullsize) {
                    width: 57vw;
                    bottom: 7.4vw;
                }

                @media(max-width: $tablet) {
                    width: 72.5vw;
                    bottom: 8.4vw;
                }

                .wrapper {
                    position: relative;
                    display: inline-block;
                    height: 100%;

                    img {
                        border-radius: $border-radius;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        z-index: 0;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        opacity: 0;
                        border-radius: $border-radius;
                        background: linear-gradient(137deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 90%);
                        transition: opacity 0.8s ease;

                        @media(max-width: $mobile) {
                            content: none;
                        }

                        @media (pointer: coarse) and (hover: none),
                        (pointer: fine) {
                            content: none;
                        }
                    }
                }

                .scroll-content {
                    position: absolute;
                    z-index: 5;
                    top: 11%;
                    left: 1.8vw;
                    right: 0;
                    width: 100%;
                    margin: auto;
                    max-width: 1115px;

                    .scroll-wrapper {
                        position: relative;
                        width: 24vw;
                        color: #fff;
                        height: 22vw;
                        padding: 0 0.5rem 0;
                        text-align: left;
                        font-size: 1rem;
                        overflow-y: scroll;

                        @media(max-width: $tablet) {
                            width: 36vw;
                            height: 26vw;
                        }

                        @media(max-width: $mobile) {
                            width: 55vw;
                        }

                        @media(min-width: $fullsize) {
                            left: 0;
                            width: 400px;
                            height: 430px;
                        }

                        @media(max-width: $laptop) {
                            font-size: calc(0.2rem + 1vw);
                        }

                        @media(max-width: $mobile) {
                            font-size: 12px;
                        }

                        &::-webkit-scrollbar {
                            width: 5px;
                        }

                        &::-webkit-scrollbar-track {
                            background-color: rgba(255, 255, 255, 0.2);
                            border-radius: $border-radius;
                            transition: background-color 0.4s ease;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: rgba(181, 143, 62, 0.5);
                            border-radius: $border-radius;
                            border: 0 solid transparent;
                            background-clip: content-box;
                            cursor: pointer;
                            transition: background-color 0.3s ease;
                        }

                    }
                }

                &:hover {
                    .wrapper:before {
                        opacity: 1;
                    }

                    .scroll-wrapper {
                        &::-webkit-scrollbar-track {
                            background-color: rgba(255, 255, 255, 0.3);
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: rgba(181, 143, 62, 0.9);
                        }
                    }
                }

            }

            .frame-section {
                h3 {
                    color: #fff;
                }
            }
        }
    }

    #gallery {
        position: relative;
        background-color: #fefefe;

        #gallery-paperfly {
            position: absolute;
            z-index: 2;
            right: 0;
            top: 10vw;
            width: 14vw;
            @include transform(rotate(30deg));

            @media(max-width: $mobile) {
                top: -6vw;
            }
        }

        #gallery-large-leaf {
            position: absolute;
            z-index: 2;
            right: 0;
            top: 20vw;
            width: 6vw;
            @include transform(rotate(-20deg));
        }

        #gallery-small-leaf {
            position: absolute;
            z-index: 2;
            right: 10vw;
            top: 10vw;
            width: 10vw;
            @include transform(rotate(20deg));
        }

        #gallery-char-1 {
            position: absolute;
            left: 0;
            z-index: 2;
            bottom: -5vw;
            width: 18vw;

            @media(min-width: $widescreen) {
                img {
                    width: 100%;
                }
            }
        }

        .top-header {
            position: relative;
            z-index: 1;

            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background: url('../assets/TOTS_Website_Frame_Gallery_left_side.png') repeat-x center top / contain;
            }
        }

        .bottom-header {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background: url('../assets/TOTS_Website_Frame_Gallery_bottom_repeat.png') repeat-x center top / contain;
            }
        }

        #gallery-slider {
            position: relative;
            z-index: 0;
            top: -3vw;
            padding: 0;


            @media(min-width: $fullsize) {
                top: -2.4vw;
            }

            @media(min-width: $widescreen) {
                top: -1.8vw;
            }

            .swiper-slide {

                &:before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    border-radius: $border-radius;
                    transition: all 0.5s ease;
                    background: rgba(0, 0, 0, 0.4);
                }

                &.swiper-slide-active:before {
                    content: none;
                }

                img {
                    border-radius: $border-radius;
                }
            }

            .swiper-arrow {
                position: absolute;
                top: 50%;
                width: 2rem;
                height: 3rem;
                z-index: 10;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: -1.5rem;

                @media(max-width: $mobile) {
                    width: 1rem;
                }

                &.swiper-prev-arrow {
                    left: 24.5vw;
                    right: auto;
                    @include transform(scaleX(-1));

                    @media(min-width: $tablet) {
                        left: 22%;
                    }

                    @media(min-width: $fullsize) {
                        left: 24%;
                    }

                    @media(max-width: $mobile) {
                        left: 65px;
                    }
                }

                &.swiper-next-arrow {
                    right: 24.5vw;
                    left: auto;

                    @media(min-width: $tablet) {
                        right: 22%;
                    }

                    @media(min-width: $fullsize) {
                        right: 24%;
                    }

                    @media(max-width: $mobile) {
                        right: 65px;
                    }
                }
            }
        }

        .frame-section {
            padding: 9vw 0 4vw;

            .frame-border {
                width: 100% !important;

                @media(max-width: $tablet) {
                    width: 120% !important;
                    left: -10%;
                }

                .frame-title {
                    height: 15%;
                    z-index: 1;

                    @media(min-width: 2050px) {
                        height: 14%;
                    }

                    @media(min-width: 2299px) {
                        height: 14%;
                    }

                    @media(min-width: 2300px) {
                        height: 12%;
                    }

                    @media(max-width: $mobile) {
                        height: 12%;
                    }
                }
            }

            h3 {
                color: #3c6b20;
            }
        }

    }

    #news {
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../assets/tots_grass_divider_top.png') no-repeat center top;
            background-size: 100%;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../assets/tots_grass_divider.png') no-repeat center bottom;
            background-size: 100%;
        }

        #news-backdrop {
            background: #fff url(../assets/tots_news_bkg.jpg) no-repeat center center;
            background-size: cover;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;
            z-index: 0;
        }

        #news-fish-1 {
            position: absolute;
            right: 3vw;
            z-index: 1;
            top: 32%;
            width: 15vw;
            @include transform(rotate(30deg));
        }

        #news-fish-2 {
            position: absolute;
            z-index: 1;
            left: 4vw;
            bottom: 6vw;
            width: 15vw;
            @include transform(rotate(65deg));
        }

        #news-content {
            position: absolute;
            z-index: 4;
            top: 52%;
            left: 0;
            right: 0;
            margin: auto;
            color: #702906;
            border-radius: $border-radius;
            padding: 0.5rem;
            transform: translateY(-50%);
            margin: 0 6.5vw;
            height: 63%;
            max-width: 1009px;

            @media(min-width: $fullsize) {
                max-width: 1109px;
            }

            @media(min-width: 1739px) {
                margin: 0 auto;
            }

            @media(max-width: $tablet) {
                margin: 0 8.5vw;
            }

            @media(max-width: $mobile) {
                top: 8.8%;
                height: 82%;
                transform: none;
                margin: 0 11vw;
            }

            .swiper {
                height: 100%;

                &.single .swiper-wrapper {
                    background-color: transparent !important;

                    .swiper-slide {
                        background-color: $white;
                        border-radius: $border-radius;
                    }
                }
            }

            .swiper-wrapper {
                background-color: $white;
                border-radius: $border-radius;

                .swiper-slide {
                    padding: 0.8rem;

                    .card {
                        height: 63%;
                        border: 0;
                        background-color: #e7e2d3;
                        color: #702906;

                        .card-title {
                            font-size: 1.3rem;
                            padding-top: 1rem;
                            font-weight: 600;
                            line-height: 1.1;

                            @media(max-width: 1799px) {
                                font-size: calc(0.5rem + 0.7vw);
                            }

                            @media(max-width: 1499px) {
                                padding-top: 0;
                                font-size: calc(0.3rem + 0.7vw);
                            }

                            @media(max-width: $mobile) {
                                font-size: calc(1.2rem - 0.5vw);
                            }

                            @media(max-width: $iphone) {
                                font-size: calc(1.2rem - 0.8vw);
                            }
                        }

                        .card-body {
                            padding: 1rem;

                            @media(max-width: $laptop) {
                                padding: 0.7rem;
                            }

                            @media(max-width: $mobile) {
                                padding: 1rem;

                            }
                        }

                        .card-footer {
                            font-size: 0.9rem;
                            background-color: transparent;
                            text-transform: uppercase;

                            a {
                                color: #702906;
                                text-decoration: none;
                            }

                            @media(max-width: $laptop) {
                                font-size: 11px;
                            }

                            img {
                                width: 30px;
                                margin-left: 0.6rem;
                            }
                        }
                    }
                }
            }

        }

        .frame-section {
            padding: 9vw 0 10vw;

            @media(max-width: $mobile) {
                padding-bottom: 18vw;
            }

            .frame-image {
                text-align: center;
                margin: auto;
                max-width: 1366px;
            }

            .frame-title {
                @media(max-width: $mobile) {
                    height: 6.6%;
                }
            }

            h3 {
                color: #fff;
            }
        }

        .cta-news {
            .more-link {
                width: 29vw;
                max-width: 290px;
                display: block;
                margin: 0.6rem auto 0;

                @media(max-width: $mobile) {
                    width: 35vw;
                }

                svg {
                    text {
                        margin: auto;
                        text-align: center;
                        font-size: calc(1.6rem + 1vw);
                        fill: #702906;
                        text-transform: uppercase;

                        @media(min-width: $fullsize) {
                            font-size: 42px;
                        }

                        @media(max-width: $mobile) {
                            font-size: calc(2.2rem + 1vw);
                        }
                    }
                }
            }
        }

    }

    #community {
        position: relative;
        background-image: url('../assets/tots_community_bkg3.jpg');
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        overflow: hidden;
        height: 27vw;
        max-height: 620px;

        @media(max-width: 1899px) {
            height: 29.5vw;
        }

        @media(max-width: $mobile) {
            padding-bottom: 1rem;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            z-index: 3;
            width: 100%;
            height: 20%;
            background: url('../assets/tots_footer_grass.png') no-repeat center bottom;
            background-size: 100%;
        }

        #community-char-1 {
            position: absolute;
            right: 0;
            bottom: -5vw;
            width: 15vw;
            z-index: 3;

            @media(min-width: 2099px) {
                bottom: 0;
            }
        }

        #community-char-2 {
            position: absolute;
            right: -12vw;
            bottom: -13vw;
            width: 22vw;
            z-index: 3;

            @media(min-width: 2099px) {
                bottom: -9vw;
            }

            @media(min-width: $widescreen) {
                bottom: -5vw;
            }
        }

        #community-overlay {
            position: absolute;
            top: 0;
            right: -70%;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.526) 40%, rgba(255, 255, 255, 0.926) 100%);

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: -100%;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, .926);
            }
        }

        .frame-section {
            padding: 3vw 0 10vw;
            z-index: 2;

            .frame-title {
                height: 18%;

                h3 {
                    color: #3c6b20;
                }
            }


        }

        .community-signup {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: auto;
            transform: translateY(-50%);

            .inner {
                padding: 0 5vw;
                max-width: 920px;
                margin: auto;

                @media(min-width: $widescreen) {
                    max-width: 970px;
                }

                @media(max-width: $desktop) {
                    padding: 0 10vw;
                }

                @media(max-width: $tablet) {
                    margin-top: 1rem;
                }
            }

            .icon-email {
                width: 6vw;

                @media(max-width: $tablet) {
                    width: 8vw;
                }

                @media(max-width: $mobile) {
                    width: 10vw;
                }
            }

            h3 {
                font-size: calc(0.5rem + 1vw);
                color: #2e4f26;
                margin-left: 0.8rem;
                line-height: 1.1;
                margin-bottom: 0;
                text-align: left;
            }

            .btn-link {
                width: 29vw;
                max-width: 290px;
                display: block;
                margin: 0.8rem auto 0;

                @media(max-width: $mobile) {
                    width: 35vw;
                }

                svg {
                    text {
                        margin: auto;
                        text-align: center;
                        font-size: calc(1.6rem + 1vw);
                        fill: #fff;
                        text-transform: uppercase;

                        @media(min-width: $fullsize) {
                            font-size: 44px;
                        }

                        @media(max-width: $mobile) {
                            font-size: calc(2.2rem + 1vw);
                        }
                    }
                }
            }
        }
    }
}
#app-footer {
    position: relative;
    z-index: 3;
    text-align: center;
    padding-bottom: 1.4rem;
    background-color: hsl(101, 22%, 25%);
    color: $white;

    .footer-brand {
        position: absolute;
        max-width: 160px;
        margin: 0 auto;
        top: -9.5rem;
        right: 0;
        left: 0;

        @media(max-width: $fullsize) {
            top: -8rem;
            max-width: 140px;
        }

        @media(max-width: $desktop) {
            top: -6rem;
            max-width: 120px;
        }

        @media(max-width: $tablet) {
            top: -5rem;
            max-width: 110px;
        }

        @media(max-width: $mobile) {
            top: -4rem;
            max-width: 90px;
        }
    }

    #app-footer-language-switch {
        #dropdown-basic {
            background-color: #2f3e28;
        }

        .locale-switch {
            display: block;

            &:hover {
                background: #e7e2d3;
            }
        }
    }

    #app-footer-social {
        padding: 2.5rem 0 2rem;

        @media(max-width: $desktop) {
            padding: 3rem 0 2rem;
        }

        a {
            background-color: $white;
            margin-right: 10px;
            height: 30px;
            width: 30px;
            text-align: center;
            padding: 3px 5px 5px 5px;
            border-radius: 50%;
            display: inline-block;
            transition: all 0.3s ease-in;

            &:last-of-type {
                margin-right: 0;
            }

            i {
                color: #3b4e32;
                transition: color 0.3s ease-in;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.2);

                i {
                    color: $white;
                }
            }
        }
    }

    #app-footer-links {
        a {
            color: $white;
            text-decoration: none;
            margin: 0.2rem 1rem;
            font-size: 0.9rem;

            @media(max-width: $tablet) {
                margin: 0.5rem 0.8rem 0.2rem;
                font-size: 0.8rem;
            }


            &:hover {
                color: rgba(255, 255, 255, 0.5);
            }
        }

        .footer-logo {
            margin: 0 0.8rem;
        }
    }

    .footer-cell {
        width: 20%;

        @media(max-width: $desktop) {
            width: 36%;
        }

        @media(max-width: $tablet) {
            margin: 2rem 0.8rem;
            width: inherit;
            justify-content: center !important;
        }

        .esrb {
            width: 65px;
            text-align: center;
            margin: auto;
        }
    }

    .footer-legal {
        max-width: 75%;
        font-size: 0.7rem;
        font-weight: 300;
        opacity: 0.5;
        margin: 2rem auto 0;

        @media(max-width: $tablet) {
            max-width: inherit;
        }
    }
}